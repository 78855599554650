// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
var $ = require('jquery');

// console.log('Hello Webpack Encore! Edit me in assets/js/app.js');

$('#toggle-leftmenu').click(function () {
    $('.rightside').toggleClass('slide');
    $('nav.leftmenu').toggleClass('slide');
    $('.darkbg').fadeToggle('fast');
});
$('.darkbg').click(function () {
    $('.rightside').removeClass('slide');
    $('nav.leftmenu').removeClass('slide');
    $('.darkbg').fadeOut('fast');
});

$('ul.menu a[data-ulname]').click(function (e) {
    e.preventDefault();
    var l = $(e.target).attr('data-ulname');
    $('ul.sub[data-name]').slideUp();
    $('ul.sub[data-name="' + l + '"]').slideToggle();
});

$('#searchchaptersform').submit(function(e){
    e.preventDefault();

    searchForChapters();

    return false;
});

window.searchForChapters = function(){
    //input should at least be 3 characters long (otherwise too many results)
    let value =  $('#freetext').val().trim();
    if (value.length < 3) {
        $('#searchresults').html('');
        $('#searchresultcount').html('(0)');
        return;
    }

    let link = $('#searchchaptersform').attr('action');
    $.post(link, {'input': value}, function(data) {

        let chapters = data.chapters;
        if (chapters.length > 0) {

            $('#searchresultcontainer').show();
            $('#searchresultcount').html('('+chapters.length+')');

            let list = '';
            for (i = 0; i < chapters.length; i++){
                list += '<li><a href="/'+chapters[i].article.category+'/'+chapters[i].slug+'">'+chapters[i].title+'</a></li>';
            }

            $('#searchresults').html(list);
        }
        else {
            $('#searchresults').html('');
            $('#searchresultcount').html('(0)');
        }
    });
}